import React, { Fragment } from 'react';
import { saiki, pesan, api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            ID: "",
            PerusahaanID: "",
            NamaPerusahaan: "",
            Tanggal: saiki(),
            NotesL: "",
            Status: "Baru",
            dataPelanggan: [],
            detailFitur: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let Field = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Pelanggan", sort: "NamaPerusahaan", type: "str" },
            { cap: "Tanggal", sort: "Tanggal", type: "date" },
            { cap: "Keterangan", sort: "Notes", type: "str" },
            { cap: "Status", sort: "SSTS", type: "str" },
        ];

        this.setState({ Field: Field });
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("fitur_api", { act: "data", q: this.state.q });
        console.log(sql);
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleForm(data) {
        if (data) {
            this.setState({
                ID: data.ID,
                PerusahaanID: data.PerusahaanID,
                NamaPerusahaan: data.NamaPerusahaan,
                Tanggal: data.Tanggal,
                NotesL: data.NotesL,
                Status: data.Status
            });
        } else {
            this.setState({
                ID: "",
                PerusahaanID: "",
                NamaPerusahaan: "",
                Tanggal: saiki(),
                NotesL: "",
                Status: "Baru"
            });
        }
        openModal("modalForm");
    }

    modalDelete(data) {
        this.setState({ Detail: data, ID: data.ID });
        openModal("modalHapus");
    }

    handleModalPelanggan() {
        this.handleCariPelanggan("");
        openModal("modalPelanggan");
    }

    async handleCariPelanggan(val) {
        let sql = await api("fitur_api", { act: "data pelanggan", q: val }, true);
        if (sql.status == "sukses") this.setState({ dataPelanggan: sql.data });
    }

    handlePilihPelanggan(data) {
        this.setState({ NamaPerusahaan: data.Nama, PerusahaanID: data.ID });
        document.getElementById('btnTutupModalCariPelanggan').click();
    }

    handleAddFitur() {
        let data = this.state.detailFitur;
        data.push({
            Fitur: "",
            Detail: "",
            Biaya: "",
            IsPaid: 0
        })
        this.setState({ detailFitur: data });
    }

    async handleDetail(data) {
        let sql = await api("fitur_api", { act: "detail", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ detailFitur: sql.data, Detail: data });
            openModal("modalDetail");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2">
                    <button className='btn btn-sm btn-default' onClick={() => this.handleForm()}>Tambah</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Req Fitur</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Admin' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables tbody={this.state.Data} thead={this.state.Field} opt={[
                            { icon: "fas fa-tasks", fn: (e) => this.handleDetail(e) },
                            { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                        ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'fitur_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="PerusahaanID" value={this.state.PerusahaanIDID} />
                                <input type='hidden' name="act" value="tambah" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Menu" : "Edit Menu"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Nama Pelanggan</label>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    <input type="text" name="NamaPerusahaan" value={this.state.NamaPerusahaan} onChange={(e) => this.setState({ NamaPerusahaan: e.target.value })} className="form-control" readOnly onFocus={() => this.handleModalPelanggan()} />
                                                    <button type='button' className='btn btn-default' onClick={() => this.handleModalPelanggan()} ><i className='fas fa-search'></i></button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan pilih pelanggan</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Tanggal Jatuh Tempo</label>
                                                <input type="date" name="Tanggal" value={this.state.Tanggal} onChange={(e) => this.setState({ Tanggal: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan masukan tanggal jatuh tempo</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' name='Notes' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan isi keterangan</div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select name='Status' className='form-select' value={this.state.Status} onChange={(e) => this.setState({ Status: e.target.value })}>
                                            <option value="Baru">Baru</option>
                                            <option value="On Progress">On Progress</option>
                                            <option value="Selesai">Selesai</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan tentukan status</div>
                                    </div>
                                    <p></p>
                                    <button className='btn btn-default w-100' type='button' onClick={() => this.handleAddFitur()}>Tambah Fitur</button>
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-stripped'>
                                            <thead>
                                                <tr>
                                                    <th>Fitur</th>
                                                    <th>Keterangan</th>
                                                    <th>Biaya</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.detailFitur.map((tr, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <input type="text" name="Fitur[]" className='form-control form-control-sm' value={tr.Fitur} onChange={(e) => {
                                                                        let data = this.state.detailFitur;
                                                                        data[i].Fitur = e.target.value;
                                                                        this.setState({ detailFitur: data });
                                                                    }} required />
                                                                </td>
                                                                <td>
                                                                    <textarea name="Detail[]" className='form-control form-control-sm' value={tr.Detail} onChange={(e) => {
                                                                        let data = this.state.detailFitur;
                                                                        data[i].Detail = e.target.value;
                                                                        this.setState({ detailFitur: data });
                                                                    }} required />
                                                                </td>
                                                                <td>
                                                                    <input type="number" name="Biaya[]" className='form-control form-control-sm' value={tr.Biaya} onChange={(e) => {
                                                                        let data = this.state.detailFitur;
                                                                        data[i].Biaya = e.target.value;
                                                                        this.setState({ detailFitur: data });
                                                                    }} required />
                                                                </td>
                                                                <td>
                                                                    <select type="text" name="IsPaid[]" className='form-select form-select-sm' value={tr.IsPaid} onChange={(e) => {
                                                                        let data = this.state.detailFitur;
                                                                        data[i].IsPaid = e.target.value;
                                                                        this.setState({ detailFitur: data });
                                                                    }} required >
                                                                        <option value="1">Sudah Dibayar</option>
                                                                        <option value="0">Belum Dibayar</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <button className='btn btn-sm btn-danger' type='button' onClick={() => {
                                                                        let data = this.state.detailFitur;
                                                                        let tempData = [];
                                                                        for (let dd in data) if (dd != i) tempData.push(data[dd]);
                                                                        this.setState({ detailFitur: tempData });
                                                                    }}>
                                                                        <i className='fas fa-trash'></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default" id='btnSimpan'><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalHapus" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'fitur_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value="hapus" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Menu" : "Edit Menu"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus Req Fitur {this.state.Detail.Nama} !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger" id='btnSimpan'><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <input type='hidden' name="act" value="hapus" />
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari data pelanggan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type='search' className='form-control' placeholder='Cari Nama / email pelanggan' onChange={(e) => this.handleCariPelanggan(e.target.value)} />
                                <div className='table-responsive'>
                                    <table className='table table-stripped'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Email</th>
                                                <th>Telp</th>
                                                <th>Alamat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.dataPelanggan.map((tr, i) => {
                                                    return (<tr key={i} onClick={(e) => this.handlePilihPelanggan(tr)}>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Email}</td>
                                                        <td>{tr.Telp}</td>
                                                        <td>{tr.Alamat}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariPelanggan'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Req Fitur</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='table-responsive'>
                                    <table className='table table-stripped'>
                                        <thead>
                                            <tr>
                                                <th>Fitur</th>
                                                <th>Keterangan</th>
                                                <th>Biaya</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.detailFitur.map((tr, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                <input type="text" name="Fitur[]" className='form-control form-control-sm' value={tr.Fitur} onChange={(e) => {
                                                                    let data = this.state.detailFitur;
                                                                    data[i].Fitur = e.target.value;
                                                                    this.setState({ detailFitur: data });
                                                                }} required />
                                                            </td>
                                                            <td>
                                                                <textarea name="Detail[]" className='form-control form-control-sm' value={tr.Detail} onChange={(e) => {
                                                                    let data = this.state.detailFitur;
                                                                    data[i].Detail = e.target.value;
                                                                    this.setState({ detailFitur: data });
                                                                }} required />
                                                            </td>
                                                            <td>
                                                                <input type="number" name="Biaya[]" className='form-control form-control-sm' value={tr.Biaya} onChange={(e) => {
                                                                    let data = this.state.detailFitur;
                                                                    data[i].Biaya = e.target.value;
                                                                    this.setState({ detailFitur: data });
                                                                }} required />
                                                            </td>
                                                            <td>
                                                                <select type="text" name="IsPaid[]" className='form-select form-select-sm' value={tr.IsPaid} onChange={(e) => {
                                                                    let data = this.state.detailFitur;
                                                                    data[i].IsPaid = e.target.value;
                                                                    this.setState({ detailFitur: data });
                                                                }} required >
                                                                    <option value="1">Sudah Dibayar</option>
                                                                    <option value="0">Belum Dibayar</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <button className='btn btn-sm btn-danger' type='button' onClick={() => {
                                                                    let data = this.state.detailFitur;
                                                                    let tempData = [];
                                                                    for (let dd in data) if (dd != i) tempData.push(data[dd]);
                                                                    this.setState({ detailFitur: tempData });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalDetail'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
