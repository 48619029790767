import React from "react";
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.greenmist.css';
import 'devextreme/dist/css/dx.darkviolet.css';

class RendTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thead: [],
            tbody: [],
            opt: [],
            group: [],
            colorTheme: localStorage.getItem("ColorTheme"),
            limit: 100
        };
    }

    componentDidMount() {
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") this.setState({ colorTheme: "dark" });
        this.handleRender();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thead !== this.props.thead || prevProps.tbody !== this.props.tbody) this.handleRender();
    }

    handleRender() {
        let thead = this.props.thead;
        let tbody = this.props.tbody;
        let opt = this.props.opt || [];
        let group = this.props.group || [];
        let limit = this.props.limit || 100;
        let colorTheme = localStorage.getItem("ColorTheme");
        this.setState({ thead, tbody, opt, group, colorTheme, limit });
    }

    handleActive(e) {
        var activeElements = document.querySelectorAll('.table-active');
        activeElements.forEach(function (element) {
            element.classList.remove('table-active');
        });
        if (e.target.tagName == "TD") {
            e.target.parentNode.classList.add("table-active");
        }
    }

    render() {
        return (
            <div className="table-responsive">
                <DataGrid
                    dataSource={this.state.tbody}
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    rowAlternationEnabled={true}
                    className={this.state.colorTheme == "dark" ? "bootstrap-theme" : "dx-theme-darkviolet"}
                >
                    {
                        this.state.thead.map((tr, ii) => {
                            if (tr.type == "number") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                            } else if (tr.type == "date") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                            } else if (tr.type == "datetime") {
                                return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                            } else if (tr.type == "opsi") {
                                return <Column caption={tr.cap} key={ii}
                                    cellRender={(rowData) => (
                                        <div className="d-flex gap-2">
                                            {
                                                this.state.opt.map((opt, i) => {
                                                    if (opt.icon != "checkbox") {
                                                        return (
                                                            <i className={opt.icon} key={i} onClick={() => opt.fn(rowData.data)}></i>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="form-check form-switch" key={i}>
                                                                <input className="form-check-input" name='IsWajib' type="checkbox" role="switch" id={"chkTable" + rowData.data.ID} checked={rowData.data.checked == 1 ? true : false} onChange={(e) => opt.fn(rowData.data)} />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    )}
                                />
                            } else if (tr.type == "index") {
                                return <Column
                                    caption={tr.cap}
                                    alignment="center"
                                    cellRender={(data) => {
                                        return data.rowIndex + 1;
                                    }}
                                />
                            } else {
                                return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                            }
                        })
                    }
                    {
                        this.state.group.map((gp, i) => {
                            return <Column dataField={gp.sort} caption={gp.cap} groupIndex={i} key={i} />
                        })
                    }
                    <Paging defaultPageSize={this.state.limit} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <GroupPanel visible={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    {/* <Scrolling mode="virtual" /> */}
                    <Summary>
                        <TotalItem column="ID" summaryType="count" />
                        <GroupItem column="ID" summaryType="count" displayFormat="{0} Data" />
                    </Summary>
                    <Pager allowedPageSizes={[50, 100, 150, 200, 1000]} showPageSizeSelector={true} />
                </DataGrid>
            </div >
        );
    }
}

export default RendTable;