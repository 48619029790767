// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
const firebaseConfig = {
    apiKey: "AIzaSyA6iuIZ3wHMJgH116o5b2SXax91zcOTqXY",
    authDomain: "gijutsu-software.firebaseapp.com",
    projectId: "gijutsu-software",
    storageBucket: "gijutsu-software.appspot.com",
    messagingSenderId: "948771982062",
    appId: "1:948771982062:web:e89bbfe11e5c28eaa841ff",
    measurementId: "G-BF20TRWLB1"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async (setTokenFound, token) => {
    return getToken(messaging, { vapidKey: 'BAliPd0psyYvWYN2e5Y9Af0_XWHtaMYpDwarXj55xdt9pM4uNlq1AZa21mRxcig6WeQGkUH-DosAWj2b9zx0DMs' }) // ganti 'PUBLIC_VAPID_KEY' dengan VAPID key baru
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                setTokenFound(true, currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(false);
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenFound(false);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });