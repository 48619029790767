import React, { Fragment } from 'react';
import { saiki, pesan, api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            ID: "",
            admin_firstname: "",
            admin_lastname: "",
            admin_username: "",
            admin_email: "",
            admin_status: "",
            admin_password: "",
            admin_password2: "",
            Akses: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let Field = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Nama", sort: "admin_firstname", type: "str" },
            { cap: "Email", sort: "admin_email", type: "str" },
            { cap: "Username", sort: "admin_username", type: "str" },
            { cap: "Status", sort: "SSTS", type: "str" },
        ];

        this.setState({ Field: Field });
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("admin_api", { act: "data", q: this.state.q });
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleForm(data) {
        if (data) {
            this.setState({
                ID: data.ID,
                admin_firstname: data.admin_firstname,
                admin_lastname: data.admin_lastname,
                admin_username: data.admin_username,
                admin_email: data.admin_email,
                admin_status: data.admin_status
            });
        } else {
            this.setState({
                ID: "",
                admin_firstname: "",
                admin_lastname: "",
                admin_username: "",
                admin_email: "",
                admin_status: "",
                admin_password: "",
                admin_password2: "",
            });
        }
        openModal("modalForm");
    }

    modalDelete(data) {
        this.setState({ Detail: data, ID: data.ID });
        openModal("modalHapus");
    }

    async handleAkses(data) {
        let sql = await api("admin_api", { act: "data akses", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ Akses: sql.data, ID: data.ID });
            openModal("modalAkses");
        }
    }

    handleChangeAkses(e, obj, i) {
        let data = this.state.Akses;
        data[i][obj] = e.target.checked == true ? 1 : 0;
        this.setState({ Akses: data });
    }

    handleChangeAksesAll(e, obj) {
        let data = this.state.Akses;
        for (let i in data) {
            data[i][obj] = e.target.checked == true ? 1 : 0;
        }
        this.setState({ Akses: data });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2">
                    <button className='btn btn-sm btn-default' onClick={() => this.handleForm()}>Tambah</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Admin</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Admin' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables tbody={this.state.Data} thead={this.state.Field} opt={[
                            { icon: "fas fa-edit", fn: (e) => this.handleForm(e) },
                            { icon: "fas fa-universal-access", fn: (e) => this.handleAkses(e) },
                            { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                        ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'admin_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value={this.state.ID == "" ? "tambah" : "edit"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Admin" : "Edit Admin"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Nama Depan</label>
                                            <input type="text" name="admin_firstname" value={this.state.admin_firstname} onChange={(e) => this.setState({ admin_firstname: e.target.value })} className="form-control" required />
                                            <div className='invalid-feedback'>Silahkan isi nama depan</div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Nama Belakang</label>
                                            <input type="text" name="admin_lastname" value={this.state.admin_lastname} onChange={(e) => this.setState({ admin_lastname: e.target.value })} className="form-control" required />
                                            <div className='invalid-feedback'>Silahkan isi path nama belakang</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type="text" name="admin_email" value={this.state.admin_email} onChange={(e) => this.setState({ admin_email: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi email</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Username</label>
                                                <input type="text" name="admin_username" value={this.state.admin_username} onChange={(e) => this.setState({ admin_username: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi id menu Username</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Password</label>
                                                <input type="password" name="admin_password" value={this.state.admin_passwrodd} onChange={(e) => this.setState({ admin_password: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi password</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Ulangi Password</label>
                                                <input type="password" name="admin_password2" value={this.state.admin_password2} onChange={(e) => this.setState({ admin_password2: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan ulangi password</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name="admin_status" type="checkbox" role="switch" id="chkStatus" value={(this.state.admin_status)} onChange={(e) => this.setState({ admin_status: e.target.checked == true ? 1 : 0 })} checked={this.state.admin_status} />
                                            <label className="form-check-label" htmlFor="chkStatus">Status</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default" id='btnSimpan'><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalHapus" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'admin_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value="hapus" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Menu" : "Edit Menu"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus menu {this.state.Detail.Nama} !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger" id='btnSimpan'><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Modal untuk hak akses */}
                <div className="modal fade" id="modalAkses" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'admin_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value="hak akses" />
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hak Akses admin</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Menu</th>
                                                    <th>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="chkAkses" onChange={(e) => this.handleChangeAksesAll(e, "Akses")} />
                                                            <label className="form-check-label" htmlFor="chkAkses">Lihat</label>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="chkTambah" onChange={(e) => this.handleChangeAksesAll(e, "Tambah")} />
                                                            <label className="form-check-label" htmlFor="chkTambah">Tambah</label>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="chkEdit" onChange={(e) => this.handleChangeAksesAll(e, "Edit")} />
                                                            <label className="form-check-label" htmlFor="chkEdit">Edit</label>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="chkHapus" onChange={(e) => this.handleChangeAksesAll(e, "Hapus")} />
                                                            <label className="form-check-label" htmlFor="chkHapus">Hapus</label>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Akses.map((tr, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    {tr.Nama}
                                                                </td>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name="Akses[]" type="checkbox" role="switch" id={`chkAkses${tr.ID}`} value={tr.Path} onChange={(e) => this.handleChangeAkses(e, 'Akses', i)} checked={tr.Akses} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name="Tambah[]" type="checkbox" role="switch" id={`chkTambah${tr.ID}`} value={tr.Path} onChange={(e) => this.handleChangeAkses(e, 'Tambah', i)} checked={tr.Tambah} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name="Edit[]" type="checkbox" role="switch" id={`chkEdit${tr.ID}`} value={tr.Path} onChange={(e) => this.handleChangeAkses(e, 'Edit', i)} checked={tr.Edit} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" name="Hapus[]" type="checkbox" role="switch" id={`chkHapus${tr.ID}`} value={tr.Path} onChange={(e) => this.handleChangeAkses(e, 'Hapus', i)} checked={tr.Hapus} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Batal</button>
                                    <button type="submit" className="btn btn-default" id='btnSimpan'><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
