import React, { Fragment } from 'react';
import { saiki, pesan, api, submitForm, openModal, numberFormat, tanggalIndo } from '../Modul';
import RendTables from '../component/RendTable';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataJenisUsaha: [],
            Data: [],
            Field: [],
            Detail: {},
            Nama: "",
            UsahaID: "",
            Alamat: "",
            Email: "",
            Telp: "",
            JenisUsaha: "",
            JenisLayanan: "Non Manufacture",
            PaymentType: "bulanan",
            MaxEmployee: 100,
            MaxLokasi: 1,
            Status: "1",
            Aktif: saiki(),
            Sampai: saiki(),
            Domains: [],
            Database: {},
            Karyawan: [],
            Folder: {},
            Menu: [],
            DataMenus: [],
            DataLog: [],
            arrDatabase: [],
            DataDatabase: [],
            Harga: 100000,
            JenisTransaksi: "Perpanjang",
            ID: "",
            q: "",
            TanggalBimbingan: saiki(),
            DataNeraca: [],
            DetailNeraca: []
        };
    }

    async componentDidMount() {
        let sql = await api("pelanggan_api", { act: "data jenis usaha" });
        if (sql.status == "sukses") this.setState({ DataJenisUsaha: sql.data, arrDatabase: sql.db });
        this.handleMain();
        let Field = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Nama", sort: "Nama", type: "str" },
            { cap: "Tanggal Daftar", sort: "Tanggal", type: "date" },
            { cap: "Sisa (Hari)", sort: "Sisa", type: "str", type: "number" },
            { cap: "Terahir Aktif", sort: "LastConnect", type: "str" },
            { cap: "Stuk", sort: "Stuk", type: "str" },
            { cap: "Ukuran DB", sort: "DBSize", type: "str" },
            { cap: "Jenis Layanan", sort: "JenisLayanan", type: "str" },
            { cap: "Tanggal Bimbingan", sort: "LastTraining", type: "date" },
            { cap: "Hasil Bimbingan", sort: "TrainingResult", type: "str" },
            { cap: "Tanggal Aktif", sort: "Aktif", type: "date" },
            { cap: "Tanggal Selesai", sort: "Sampai", type: "date" },
            { cap: "Status", sort: "SSTS", type: "str" },
            { cap: "Jenis Usaha", sort: "JenisUsaha", type: "str" },
            { cap: "Telp", sort: "Telp", type: "str" },
            { cap: "Email", sort: "Email", type: "str" },
            { cap: "Max User", sort: "MaxEmployee", type: "number" },
            { cap: "Mac Cabang", sort: "MaxLokasi", type: "str" },
        ];

        this.setState({ Field: Field });
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("pelanggan_api", { act: "data", q: this.state.q });
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleEdit(e) {
        if (e) {
            this.setState({
                Detail: e,
                ID: e.ID,
                Nama: e.Nama,
                Alamat: e.Alamat,
                Email: e.Email,
                Telp: e.Telp,
                MaxEmployee: e.MaxEmployee,
                MaxLokasi: e.MaxLokasi,
                JenisUsaha: e.JenisUsaha,
                Aktif: e.Aktif,
                Sampai: e.Sampai,
                PaymentType: e.PaymentType,
                JenisLayanan: e.JenisLayanan,
                UsahaID: e.UsahaID,
                Status: e.Status,
                Harga: 100000,
                DataDatabase: []
            });
        } else {
            let sql = await api("pelanggan_api", { act: "data menu perusahaan", Database: "master2" });

            this.setState({
                Detail: {},
                ID: "",
                Nama: "",
                Alamat: "",
                Email: "",
                Telp: "",
                JenisUsaha: "",
                MaxEmployee: 100,
                MaxLokasi: 1,
                Aktif: saiki(),
                Sampai: saiki(),
                JenisLayanan: "Non Manufacture",
                PaymentType: "bulanan",
                UsahaID: "",
                Status: "1",
                Harga: 100000,
                DataDatabase: sql.data
            });
        }
        openModal("modalForm");
    }

    async handleDetail(e) {
        let sql = await api("pelanggan_api", { act: "detail", ID: e.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ Detail: e, Domains: sql.domain, Karyawan: sql.karyawan, Database: sql.database, Folder: sql.folder, DataNeraca: sql.neraca });
            openModal("modalDetail");
        } else {
            pesan("Error", "Mohon maaf data bermasalah", "danger");
        }
    }

    handleDetailKaryawan() {
        openModal("modalKaryawan");
    }

    async handleMenu(e) {
        let sql = await api("pelanggan_api", { act: "data menu", ID: e.ID });
        if (sql.status == "sukses") this.setState({ Menu: sql.data, ID: e.ID });
        openModal("modalMenu");
    }

    handleModalCariMenu() {
        this.handleCariMenu("");
        openModal("modalCariMenu");
    }

    async handleCariMenu(val) {
        let sql = await api("pelanggan_api", { act: "data menus", q: val });
        if (sql.status == "sukses") this.setState({ DataMenus: sql.data });
    }

    async handlePilihMenu(ID) {
        let sql = await api("pelanggan_crud", { act: "tambah menu", ID: ID, Perusahaan: this.state.ID });
        if (sql.status == "sukses") {
            this.setState({ Menu: sql.data });
            document.getElementById('btnTutupModalCariMenu').click();
        } else {
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    async handleDeleteMenu(e) {
        let sql = await api("pelanggan_crud", { act: "hapus menu", ID: e.ID, Perusahaan: this.state.ID });
        if (sql.status == "sukses") {
            this.setState({ Menu: sql.data });
        } else {
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    async handleBackupDB(e, DB) {
        e.target.className = "spinner-border spinner-border-sm text-primary";
        e.target.disabled = true;
        let sql = await api("pelanggan_api", { act: "backup db", Database: DB });
        if (sql.status == "sukses") {
            e.target.disabled = false;
            e.target.className = "fas fa-download";
            window.open(sql.pesan);
            pesan("Berhasil", "Berhasil backup", "primary");
        } else {
            e.target.disabled = false;
            e.target.className = "fas fa-download";
            pesan("Gagal", sql.pesan, "danger");
        }
    }

    async handleLog(data) {
        let sql = await api("pelanggan_api", { act: "data log", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ DataLog: sql.data });
            openModal("modalLog");
        }
    }

    async handleGetMenu(e) {
        let sql = await api("pelanggan_api", { act: "data menu perusahaan", Database: e.target.value });
        if (sql.status == "sukses") this.setState({ DataDatabase: sql.data })
    }

    handleProses(data) {
        this.setState({ Detail: data, ID: data.ID });
        openModal("modalProses");
    }

    handleBimbingan() {
        openModal("modalBimbingan");
    }

    async handleDetailNeraca(data) {
        let sql = await api("pelanggan_api", { act: "detail neraca", ID: data.ID }, true);
        if (sql.status == "sukses") {
            this.setState({ DetailNeraca: JSON.parse(sql.data) });
            openModal("modalDetailNeraca");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex gap-2">
                    <button className='btn btn-sm btn-default' onClick={() => this.handleEdit(false)}>Tambah Pelanggan</button>
                    <button className='btn btn-sm btn-default' onClick={() => this.handleBimbingan(false)}> + Jadwal Bimbingan</button>
                </div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Data Pelanggan</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables tbody={this.state.Data}
                            thead={this.state.Field}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleEdit(e) },
                                { icon: "fas fa-info-circle", fn: (e) => this.handleDetail(e) },
                                { icon: "fas fa-bars", fn: (e) => this.handleMenu(e) },
                                { icon: "fas fa-bug", fn: (e) => this.handleLog(e) },
                                { icon: "fas fa-redo", fn: (e) => this.handleProses(e) }
                            ]}
                            group={[{ cap: "Status", sort: "SSTS" }, { cap: "Jenis Layanan", sort: "JenisLayanan" }]}
                        />
                    </div>
                </div>

                {/* Untuk form */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'pelanggan_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah" : "edit"} />
                            <input type='hidden' name="ID" value={this.state.ID} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah" : "Edit"} Perusahaan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type='text' className='form-control' name="Nama" placeholder='Nama Perusahaan' value={this.state.Nama} onChange={(e) => this.setState({ Nama: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan masukan nama</div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Jenis Usaha</label>
                                                <select className='form-select' name="UsahaID" value={this.state.UsahaID} onChange={(e) => this.setState({ UsahaID: e.target.value })} required>
                                                    {
                                                        this.state.DataJenisUsaha.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Detail Usaha</label>
                                                <input type='text' className='form-control' name="JenisUsaha" placeholder='Detail Usaha' value={this.state.JenisUsaha} onChange={(e) => this.setState({ JenisUsaha: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan detail usaha</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Jenis Layanan</label>
                                                <select className='form-select' name="JenisLayanan" value={this.state.JenisLayanan} onChange={(e) => this.setState({ JenisLayanan: e.target.value })} required >
                                                    <option value="Non Manufacture">Non Manufacture</option>
                                                    <option value="Manufacture">Manufacture</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih jenis layanan</div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type='text' className='form-control' name="Telp" placeholder='Telp Perusahaan' value={this.state.Telp} onChange={(e) => this.setState({ Telp: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan masukan Telp</div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type='email' className='form-control' name="Email" placeholder='Email Perusahaan' value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                                                <div className='invalid-feedback'>Silahkan masukan Email</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Tanggal Aktif</label>
                                                <input type='date' className='form-control' name="Aktif" value={this.state.Aktif} onChange={(e) => this.setState({ Aktif: e.target.value })} required disabled={this.state.ID == "" ? false : true} />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal aktif</div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Tanggal Selesai</label>
                                                <input type='date' className='form-control' name="Sampai" value={this.state.Sampai} onChange={(e) => this.setState({ Sampai: e.target.value })} required disabled={this.state.ID == "" ? false : true} />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Selesai</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Max Karyawan</label>
                                                <input type='number' className='form-control' min="1" name="MaxEmployee" value={this.state.MaxEmployee} onChange={(e) => this.setState({ MaxEmployee: e.target.value })} required disabled={this.state.ID == "" ? false : true} />
                                                <div className='invalid-feedback'>Silahkan masukan max employee</div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Max Cabang</label>
                                                <input type='number' className='form-control' min="1" name="MaxLokasi" value={this.state.MaxLokasi} onChange={(e) => this.setState({ MaxLokasi: e.target.value })} required disabled={this.state.ID == "" ? false : true} />
                                                <div className='invalid-feedback'>Silahkan masukan max cabang</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Jenis Langganan</label>
                                                <select className='form-select' name="PaymentType" value={this.state.PaymentType} onChange={(e) => this.setState({ PaymentType: e.target.value })} required disabled={this.state.ID == "" ? false : true}>
                                                    <option value="bulanan">Bulanan</option>
                                                    <option value="tahunan">Tahunan</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Jenis Langganan</div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Harga</label>
                                                <input type="number" min="1" className='form-control' name="Harga" value={this.state.Harga} onChange={(e) => this.setState({ Harga: e.target.value })} required disabled={this.state.ID == "" ? false : true} />
                                                <div className='invalid-feedback'>Silahkan masukan harga langganan</div>
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label>Bukti Transfer</label>
                                                <input type="file" accept='image/*' className='form-control' name="File" required disabled={this.state.ID == "" ? false : true} />
                                                <div className='invalid-feedback'>Silahkan pilih bukti transfer</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' name="Alamat" placeholder='Alamat Perusahaan' value={this.state.Alamat} onChange={(e) => this.setState({ Alamat: e.target.value })} required />
                                        <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                    </div>
                                    {this.state.ID == "" ?
                                        <Fragment>
                                            <div className='form-group'>
                                                <label>Duplikat Menu Dari</label>
                                                <select className='form-select' name="Menu" onChange={(e) => this.handleGetMenu(e)} required>
                                                    <option value="master2">Master</option>
                                                    {
                                                        this.state.arrDatabase.map((opt, i) => {
                                                            return (<option value={opt.Database} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name="Status" type="checkbox" role="switch" id="chkStatus" value={(this.state.Status)} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} checked={this.state.Status} />
                                            <label className="form-check-label" htmlFor="chkStatus">Aktif</label>
                                        </div>
                                    }
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Nama</th>
                                                    <th>Keterangan</th>
                                                    <th>Posisi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataDatabase.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td><button type='button' className='btn' onClick={(e) => {
                                                                let data = [], temp = this.state.DataDatabase;
                                                                for (let dd in temp) if (dd != i) data.push(temp[dd]);
                                                                this.setState({ DataDatabase: data });
                                                            }}><i className='fas fa-trash'></i></button></td>
                                                            <td>
                                                                <input type="hidden" name="MenuID[]" value={tr.ID} />
                                                                <input type="text" className='form-control fotm-control-sm' name="NamaMenu[]" value={tr.Nama} onChange={(e) => {
                                                                    let data = this.state.DataDatabase;
                                                                    data[i].Nama = e.target.value;
                                                                    this.setState({ DataDatabase: data });
                                                                }} required />
                                                            </td>
                                                            <td>{tr.Keterangan}</td>
                                                            <td>{tr.Posisi}</td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Tutup</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Untuk detail */}
                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Perusahaan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <table className='table table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Nama Database</td>
                                            <td>:</td>
                                            <td>{this.state.Database.Database}</td>
                                        </tr>
                                        <tr>
                                            <td>Ukuran Database</td>
                                            <td>:</td>
                                            <td>{this.state.Database.Ukuran} MB <i className='fas fa-download' onClick={(e) => this.handleBackupDB(e, this.state.Database.Database)}></i></td>
                                        </tr>
                                        <tr>
                                            <td>Ukuran Files</td>
                                            <td>:</td>
                                            <td>{this.state.Folder.jumlah} Files, {this.state.Folder.ukuran} MB</td>
                                        </tr>
                                        <tr>
                                            <td>Jumlah Karyawan</td>
                                            <td>:</td>
                                            <td>{this.state.Karyawan.length} Orang <i className='fas fa-info' onClick={(e) => this.handleDetailKaryawan()}></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6>Neraca</h6>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Tanggal</th>
                                                <th>Tanggal Buat</th>
                                                <th>Aset</th>
                                                <th>Loabilitas</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataNeraca.map((tr, i) => {
                                                    return (<tr key={i} onClick={(e) => this.handleDetailNeraca(tr)}>
                                                        <td>{tanggalIndo(tr.Tanggal)}</td>
                                                        <td>{tr.TimeCreated}</td>
                                                        <td>{numberFormat(tr.Aset)}</td>
                                                        <td>{numberFormat(tr.Liabilitas)}</td>
                                                        <td>{numberFormat(tr.Balance)}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk form */}
                <div className="modal fade" id="modalDetailNeraca" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Neraca</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Group</th>
                                                <th>Akun</th>
                                                <th>Nilai</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DetailNeraca.map((tr, i) => {
                                                    return (<tr key={i}>
                                                        <td>{tr.Posisi}</td>
                                                        <td>{tr.GroupType}</td>
                                                        <td>{tr.AkunID} - {tr.AkunName}</td>
                                                        <td>{numberFormat(tr.Total)}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div >

                {/* Untuk detail Karyawan */}
                <div className="modal fade" id="modalKaryawan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Karyawan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables tbody={this.state.Karyawan} thead={[
                                    { cap: "Nama", sort: "Nama", type: "str" },
                                    { cap: "Email", sort: "Email", type: "str" },
                                    { cap: "Telp", sort: "Telp", type: "str" },
                                    { cap: "Terahir Aktif", sort: "LastConnect", type: "str" },
                                ]} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk log */}
                <div className="modal fade" id="modalLog" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Log</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables tbody={this.state.DataLog} thead={[
                                    { cap: "Tanggal", sort: "DocDate", type: "str" },
                                    { cap: "ACT", sort: "Act", type: "str" },
                                    { cap: "Crud", sort: "Crud", type: "str" },
                                    { cap: "Data", sort: "Data", type: "str" },
                                ]} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Menu Perusahaan</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <button type='button' className='btn btn-sm btn-default' onClick={() => this.handleModalCariMenu()}>Tambah Menu</button>
                                <RendTables tbody={this.state.Menu} thead={[
                                    { cap: "Nama", sort: "Nama", type: "str" },
                                    { cap: "Path", sort: "Path", type: "str" },
                                    { cap: "Jenis", sort: "Type", type: "str" },
                                    { cap: "Keterangan", sort: "Keterangan", type: "str" }
                                ]} opt={[
                                    { icon: "fas fa-trash-alt", fn: (e) => this.handleDeleteMenu(e) }
                                ]} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalCariMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Menu</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='input-group'>
                                    <input type="search" className='form-control' placeholder='Cari Menu' onChange={(e) => this.handleCariMenu(e.target.value)} />
                                    <button type='button' className='btn btn-default'>
                                        <i className='fas fa-search'></i>
                                    </button>
                                </div>
                                <p></p>
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Path</th>
                                                <th>Keterangan</th>
                                                <th>Jenis</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataMenus.map((td, i) => {
                                                    return (
                                                        <tr key={i} onClick={() => this.handlePilihMenu(td.ID)}>
                                                            <td>{td.Nama}</td>
                                                            <td>{td.Path}</td>
                                                            <td>{td.Keterangan}</td>
                                                            <td>{td.IsGroup == 1 ? "Group" : "Menu"}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk Detail proses */}
                <div className="modal fade" id="modalProses" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'pelanggan_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Proses Perusahaan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type="hidden" name="act" value="proses" />
                                    <input type="hidden" name="ID" value={this.state.ID} />
                                    <div className='form-group'>
                                        <label>Jenis Transaksi</label>
                                        <select className='form-select' name="Jenis" value={this.state.JenisTransaksi} onChange={(e) => this.setState({ JenisTransaksi: e.target.value })} required>
                                            <option value="Perpanjang">Perpanjang</option>
                                            <option value="Ganti Paket">Ganti Paket</option>
                                        </select>
                                    </div>
                                    {
                                        this.state.JenisTransaksi == "Perpanjang" ?
                                            <Fragment>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Tanggal Perpanjang</label>
                                                            <input type='date' className='form-control' name="Tanggal" value={this.state.Aktif} onChange={(e) => this.setState({ Aktif: e.target.value })} required />
                                                            <div className='invalid-feedback'>Silahkan masukan Tanggal aktif</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Tanggal Selesai</label>
                                                            <input type='date' className='form-control' name="Sampai" value={this.state.Sampai} onChange={(e) => this.setState({ Sampai: e.target.value })} required />
                                                            <div className='invalid-feedback'>Silahkan masukan Tanggal Selesai</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div className='form-group'>
                                                            <label>Jenis Langganan</label>
                                                            <select className='form-select' name="PaymentType" value={this.state.PaymentType} onChange={(e) => this.setState({ PaymentType: e.target.value })} required>
                                                                <option value="bulanan">Bulanan</option>
                                                                <option value="tahunan">Tahunan</option>
                                                            </select>
                                                            <div className='invalid-feedback'>Silahkan masukan Jenis Langganan</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='form-group'>
                                                            <label>Harga</label>
                                                            <input type="number" min="1" className='form-control' name="Harga" value={this.state.Harga} onChange={(e) => this.setState({ Harga: e.target.value })} required />
                                                            <div className='invalid-feedback'>Silahkan masukan harga langganan</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='form-group'>
                                                            <label>Bukti Transfer</label>
                                                            <input type="file" accept='image/*' className='form-control' name="File" required />
                                                            <div className='invalid-feedback'>Silahkan pilih bukti transfer</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Max Karyawan</label>
                                                            <input type='number' className='form-control' min="1" name="MaxEmployee" value={this.state.MaxEmployee} onChange={(e) => this.setState({ MaxEmployee: e.target.value })} required />
                                                            <div className='invalid-feedback'>Silahkan masukan max employee</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Max Cabang</label>
                                                            <input type='number' className='form-control' min="1" name="MaxLokasi" value={this.state.MaxLokasi} onChange={(e) => this.setState({ MaxLokasi: e.target.value })} required />
                                                            <div className='invalid-feedback'>Silahkan masukan max cabang</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Harga</label>
                                                            <input type="number" min="1" className='form-control' name="Harga" value={this.state.Harga} onChange={(e) => this.setState({ Harga: e.target.value })} required />
                                                            <div className='invalid-feedback'>Silahkan masukan harga langganan</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='form-group'>
                                                            <label>Bukti Transfer</label>
                                                            <input type="file" accept='image/*' className='form-control' name="File" required />
                                                            <div className='invalid-feedback'>Silahkan pilih bukti transfer</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Tutup</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk form */}
                <div className="modal fade" id="modalBimbingan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={(e) => submitForm(e, { crud: 'pelanggan_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                            <input type='hidden' name="act" value={this.state.ID == "" ? "tambah bimbingan" : "edit bimbingan"} />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Bimbingan Perusahaan</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Perusahaan</label>
                                                <select name="Perusahaan" className='form-select' required>
                                                    {
                                                        this.state.Data.map((opt, i) => {
                                                            return (<option value={opt.ID}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" value={this.state.TanggalBimbingan} onChange={(e) => this.setState({ TanggalBimbingan: e.target.value })} name='Tanggal' className='form-control' required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Hasil</label>
                                        <input type="text" name='Status' className='form-control' required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Keteragan</label>
                                        <textarea name='Keterangan' className='form-control' required />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalText'>Tutup</button>
                                    <button type="submit" className="btn btn-default"> <i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Fragment >
        )
    }

}

export default Dashboard;
