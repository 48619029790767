import React, { Fragment } from 'react';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NamaLokasi: "Pusat",
            NamaUser: "Heru Prasetia"
        };
    }

    render() {
        return (<h5>Hallo ini menu dashboard</h5>)
    }

}

export default Dashboard;
