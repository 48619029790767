import React, { Fragment } from 'react';
import { host, pesan, api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';
import { Field } from 'devextreme-react/cjs/data-grid';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataLogin: [],
            FieldLogin: [],
            UserID: 0
        };
    }

    async componentDidMount() {
        this.handleMain();
        let Field = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Nama", sort: "Nama", type: "str" },
            { cap: "Email", sort: "Email", type: "str" },
            { cap: "Perusahaan", sort: "Perusahaan", type: "str" },
            { cap: "Telp", sort: "Telp", type: "str" },
            { cap: "Status", sort: "SSTS", type: "str" }
        ];

        this.setState({ Field: Field });
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("user_api", { act: "data", q: this.state.q });
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    modalLogout(data) {
        this.setState({ UserID: data.ID });
        openModal("modalLogout");
    }

    modalHapus(data) {
        if (data.IsOwner == 0) {
            this.setState({ UserID: data.ID });
            openModal("modalHapus");
        } else {
            pesan("Tidak Bisa", "User Tipe Owner tidak bisa di hapus", "danger");
        }
    }

    async modalDetail(data) {
        let FieldLogin = [
            { cap: "Taggal Login", sort: "Waktu", type: "str" },
            { cap: "OS", sort: "OS", type: "str" },
            { cap: "Lokasi", sort: "Lokasi", type: "str" },
            { cap: "Terahir Terhubung", sort: "LastConnect", type: "str" },
            { cap: "Status", sort: "SSTS", type: "str" },
        ]

        let sql = await api("user_api", { act: "detail", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ DataLogin: sql.data, FieldLogin: FieldLogin });
            openModal("modalDetail");
        }
    }

    async handleLogoutSesi(data) {
        let sql = await api("user_crud", { act: "logout sesi", ID: data.ID });
        if (sql.status == "sukses") {
            let sql = await api("user_api", { act: "detail", ID: data.UserID });
            this.setState({ DataLogin: sql.data });
            pesan(sql.status, sql.pesan, "primary");
        } else {
            pesan(sql.status, sql.pesan, "danger");
        }
    }

    async handleLogoutSesiAll() {
        let sql = await api("user_crud", { act: "logout user", ID: this.state.UserID });
        if (sql.status == "sukses") {
            this.handleMain();
            document.getElementById('btnTutupModalLogoutAll').click();
            pesan(sql.status, sql.pesan, "primary");
        } else {
            pesan(sql.status, sql.pesan, "danger");
        }
    }

    async handleHapus() {
        let sql = await api("user_crud", { act: "hapus user", ID: this.state.UserID }, true);
        if (sql.status == "sukses") {
            this.handleMain();
            document.getElementById('btnTutupModalHapus').click();
            pesan(sql.status, sql.pesan, "primary");
        } else {
            pesan(sql.status, sql.pesan, "danger");
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Menu Users</div>
                            <div className="col-md-3 d-flex gap-1">
                                <div className="input-group gap-2">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field} opt={[
                                { icon: "fas fa-sign-out-alt", fn: (e) => this.modalLogout(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalHapus(e) },
                                { icon: "fas fa-info-circle", fn: (e) => this.modalDetail(e) }
                            ]}
                            group={[{ sort: "Perusahaan", cap: "Perusahaan" }]}
                            limit={1000}
                        />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail User</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables tbody={this.state.DataLogin} thead={this.state.FieldLogin} opt={[
                                    { icon: "fas fa-sign-out-alt", fn: (e) => this.handleLogoutSesi(e) }
                                ]} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalLogout" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Logout User</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin akan logout user ini dari semua sesi!!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalLogoutAll'>Tutup</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => this.handleLogoutSesiAll()}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalHapus" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Hapus User</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h5>Apakah anda yakin akan Hapus user ini!!</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapus'>Tutup</button>
                                <button type="button" className="btn btn-danger" onClick={(e) => this.handleHapus()}>Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Dashboard;
