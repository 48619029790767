import React, { Fragment } from 'react';
import { saiki, pesan, api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataAkses: [],
            FieldAkses: [],
            DetailAkses: {},
            DataMenu: [],
            FieldMenu: [
                { cap: "", sort: "", type: "opsi" },
                { cap: "Nama", sort: "Nama", type: "str" },
                { cap: "Path", sort: "Path", type: "str" },
                { cap: "Keterangan", sort: "Keterangan", type: "str" },
                { cap: "Posisi", sort: "Posisi", type: "str" },
            ],
            Akses: [],
            q: "",
            ID: "",
            Nama: "",
            Path: "",
            Keterangan: "",
            Icon: "",
            Status: 1,
            Posisi: "root",
            IsOrder: 0,
            IdMenu: "",
            IsGroup: 0,
            App: "",
            Penjelasan: "",
            DataPosisi: [],
            Tutorial: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let Field = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Nama", sort: "Nama", type: "str" },
            { cap: "Path", sort: "Path", type: "str" },
            { cap: "Keterangan", sort: "Keterangan", type: "str" },
            { cap: "Jenis", sort: "Jenis", type: "str" },
            { cap: "Posisi", sort: "Posisi", type: "str" },
            { cap: "No Urut", sort: "IsOrder", type: "number" },
            { cap: "App", sort: "App", type: "str" },
            { cap: "Status", sort: "SSTS", type: "str" },
        ];

        let FieldAkses = [
            { cap: "", sort: "", type: "opsi" },
            { cap: "Nama", sort: "Nama", type: "str" },
            { cap: "Jenis Usaha", sort: "JenisUsaha", type: "str" },
        ];

        this.setState({ Field: Field, FieldAkses: FieldAkses });
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("menu_api", { act: "data", q: this.state.q });
        let Posisi = [], Tutorial = [];
        if (sql.status == "sukses") {
            for (let dd of sql.data) if (!Posisi.includes(dd.Posisi)) Posisi.push(dd.Posisi);
            this.setState({ Data: sql.data, DataPosisi: Posisi });
        }
    }

    async handleMainAkses() {
        let sql = await api("menu_api", { act: "data hak akses", q: this.state.q });
        if (sql.status == "sukses") {
            this.setState({ DataAkses: sql.data });
        }
    }

    async handleForm(data) {
        if (data) {
            this.setState({
                ID: data.ID,
                Nama: data.Nama,
                Path: data.Path,
                Keterangan: data.Keterangan,
                Icon: data.Icon,
                Status: data.Status,
                Posisi: data.Posisi,
                IdMenu: data.IdMenu,
                IsGroup: data.IsGroup,
                IsOrder: data.IsOrder,
                Penjelasan: data.Penjelasan,
                App: data.App,
                Tutorial: data.Tutorial ? JSON.parse(data.Tutorial) : []
            });
        } else {
            this.setState({
                ID: "",
                Nama: "",
                Path: "",
                Keterangan: "",
                Icon: "",
                Status: 1,
                Posisi: "root",
                IsOrder: 0,
                IdMenu: "",
                Penjelasan: "",
                IsGroup: 0,
                App: "",
                Tutorial: []
            });
        }
        openModal("modalForm");
    }

    modalDelete(data) {
        this.setState({ Detail: data, ID: data.ID });
        openModal("modalHapus");
    }

    async handleFormAkses(data) {
        let sql = await api("menu_api", { act: "detail hak akses", ID: data.ID });
        if (sql.status == "sukses") {
            let Temp = [], Akses = [];
            if (sql.data != false) {
                Akses = JSON.parse(sql.data.Akses);
                for (let dd of sql.menu) {
                    dd.checked = false;  // Default value set to false
                    for (let ii of Akses) {
                        if (dd.Path == ii.Path) {
                            dd.checked = true; // Set to true if match
                            break;  // Stop the loop once matched
                        }
                    }
                    Temp.push(dd);  // Push the item into Temp
                }
            } else {
                for (let dd of sql.menu) {
                    dd.checked = false;
                    Temp.push(dd);
                }
            }

            this.setState({ DetailAkses: sql.data || {}, DataMenu: Temp, Akses: Akses, ID: data.ID || "" });
            openModal("modalFormAkses");
        }
    }

    handlePilihMenu(data) {
        let chk = document.getElementById(`chkTable${data.ID}`);
        let Menu = this.state.DataMenu;
        let Akses = [];
        for (let dd in Menu) {
            if (Menu[dd].ID == data.ID) {
                Menu[dd].checked = chk.checked;
            }
            if (Menu[dd].checked == true) Akses.push(Menu[dd]);
        }
        let detail = this.state.DetailAkses;
        detail.Akses = Akses;
        this.setState({ DataMenu: Menu, DetailAkses: detail, Akses: Akses });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Master Menu</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={() => this.handleMainAkses()}>Template Hak Akses</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                <p></p>
                                <div className="row">
                                    <div className="col-md-6 main-title">Menu</div>
                                    <div className="col-md-6 d-flex gap-1">
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleForm()}>Tambah</button>
                                        <div className="input-group gap-2">
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Konten' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <RendTables
                                    tbody={this.state.Data}
                                    thead={this.state.Field}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]}
                                    group={[{ cap: "App", sort: "App" }, { cap: "Posisi", sort: "Posisi" }]}
                                />
                            </div>
                            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                <p></p>
                                <div className="row">
                                    <div className="col-md-9 main-title">Template Akses</div>
                                    <div className="col-md-3 d-flex justify-content-end">
                                        <button className='btn btn-sm btn-default' onClick={() => this.handleFormAkses({ ID: "" })}>Tambah</button>
                                    </div>
                                </div>
                                <RendTables
                                    tbody={this.state.DataAkses}
                                    thead={this.state.FieldAkses}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleFormAkses(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDeleteAkses(e) }
                                    ]}
                                    group={[{ cap: "Jenis Usaha", sort: "JenisUsaha" }]}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal form hak akses */}
                <div className="modal fade" id="modalFormAkses" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'menu_crud', fn: () => this.handleMainAkses(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="Akses" value={JSON.stringify(this.state.Akses)} />
                                <input type='hidden' name="act" value={this.state.ID == "" ? "tambah hak akses" : "edit hak akses"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Hak Akses" : "Edit Hak Akses"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Nama</label>
                                            <input type="text" name="Nama" value={this.state.DetailAkses.Nama || ""} onChange={(e) => {
                                                let data = this.state.DetailAkses;
                                                data.Nama = e.target.value;
                                                this.setState({ DetailAkses: data })
                                            }
                                            } className="form-control" required />
                                            <div className='invalid-feedback'>Silahkan isi nama menu</div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Jenis Usaha</label>
                                            <select name="JenisUsaha" value={this.state.DetailAkses.JenisUsaha || ""} onChange={(e) => {
                                                let data = this.state.DetailAkses;
                                                data.JenisUsaha = e.target.value;
                                                this.setState({ DetailAkses: data })
                                            }
                                            } className="form-select" required >
                                                <option value="Non Manufacture">Non Manufacture</option>
                                                <option value="Manufacture">Manufacture</option>
                                            </select>
                                            <div className='invalid-feedback'>Silahkan isi path dari menu</div>
                                        </div>
                                    </div>
                                    <p></p>
                                    <RendTables
                                        tbody={this.state.DataMenu}
                                        thead={this.state.FieldMenu}
                                        opt={[
                                            {
                                                icon: "checkbox", fn: (e) => this.handlePilihMenu(e, {})
                                            }
                                        ]}
                                        group={[{ cap: "Posisi", sort: "Posisi" }]}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalFormAkses'>Batal</button>
                                    <button type="submit" className="btn btn-default" id='btnSimpanHakAkses'><i className="fas fa-trash"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'menu_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value={this.state.ID == "" ? "tambah" : "edit"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Menu" : "Edit Menu"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Nama</label>
                                            <input type="text" name="Nama" value={this.state.Nama} onChange={(e) => this.setState({ Nama: e.target.value })} className="form-control" required />
                                            <div className='invalid-feedback'>Silahkan isi nama menu</div>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Path</label>
                                            <input type="text" name="Path" value={this.state.Path} onChange={(e) => this.setState({ Path: e.target.value })} className="form-control" required />
                                            <div className='invalid-feedback'>Silahkan isi path dari menu</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Ixon</label>
                                                <input type="text" name="Icon" value={this.state.Icon} onChange={(e) => this.setState({ Icon: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi icon menu</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>IDMenu</label>
                                                <input type="text" name="IdMenu" value={this.state.IdMenu} onChange={(e) => this.setState({ IdMenu: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi id menu keterangan menu</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Posisi</label>
                                                <select name="Posisi" value={this.state.Posisi} onChange={(e) => this.setState({ Posisi: e.target.value })} className="form-select" required >
                                                    {
                                                        this.state.DataPosisi.map((opt, i) => {
                                                            return (<option value={opt} key={i}>{opt}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih posisi menu</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>No Urut</label>
                                                <input type="number" name="IsOrder" value={this.state.IsOrder} onChange={(e) => this.setState({ IsOrder: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi no urut keterangan menu</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Aplikasi</label>
                                                <select name="App" value={this.state.App} onChange={(e) => this.setState({ App: e.target.value })} className="form-select" required >
                                                    <option value="POS">Pos</option>
                                                    <option value="ADMIN">Admin</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih posisi menu</div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label>Keterangan</label>
                                                <input type="text" name="Keterangan" value={this.state.Keterangan} onChange={(e) => this.setState({ Keterangan: e.target.value })} className="form-control" required />
                                                <div className='invalid-feedback'>Silahkan isi path keterangan menu</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='input-group gap-2'>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name="Status" type="checkbox" role="switch" id="chkStatus" value={(this.state.Status)} onChange={(e) => this.setState({ Status: e.target.checked == true ? 1 : 0 })} checked={this.state.Status} />
                                            <label className="form-check-label" htmlFor="chkStatus">Status</label>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name="IsGroup" type="checkbox" role="switch" id="chkGroup" value={(this.state.IsGroup)} onChange={(e) => this.setState({ IsGroup: e.target.checked == true ? 1 : 0 })} checked={this.state.IsGroup} />
                                            <label className="form-check-label" htmlFor="chkGroup">Group</label>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Konten</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={this.state.Penjelasan || ""}
                                            onChange={(e) => this.setState({ Penjelasan: e })}
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }],
                                                    [{ color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#cccccc', '#888888'] }],
                                                    ['clean'],
                                                ],
                                            }}
                                        />
                                        <div className='invalid-feedback'>Data Harus di isi</div>
                                    </div>
                                    <input type="hidden" name="Penjelasan" value={this.state.Penjelasan || ""} />
                                    <p></p>
                                    <h6>Tutorial</h6>
                                    <input type="hidden" name="Tutorial" value={JSON.stringify(this.state.Tutorial)} />
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Element</th>
                                                    <th>Intro</th>
                                                    <th>Position</th>
                                                    <th>Event</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.Tutorial.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <input type="text" className="form-control" value={tr.element} onChange={(e) => {
                                                                    let data = this.state.Tutorial;
                                                                    data[i].element = e.target.value;
                                                                    this.setState({ Tutorial: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" value={tr.intro} onChange={(e) => {
                                                                    let data = this.state.Tutorial;
                                                                    data[i].intro = e.target.value;
                                                                    this.setState({ Tutorial: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" value={tr.position} onChange={(e) => {
                                                                    let data = this.state.Tutorial;
                                                                    data[i].position = e.target.value;
                                                                    this.setState({ Tutorial: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" value={tr.event} onChange={(e) => {
                                                                    let data = this.state.Tutorial;
                                                                    data[i].event = e.target.value == "" ? undefined : e.target.value;
                                                                    this.setState({ Tutorial: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <button className='btn' type='button' onClick={() => {
                                                                    let dd = this.state.Tutorial;
                                                                    let temp = [];
                                                                    for (let ii in dd) if (ii != i) temp.push(dd[ii]);
                                                                    this.setState({ Tutorial: temp });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default" id='btnSimpan'><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalHapus" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'menu_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.ID} />
                                <input type='hidden' name="act" value="hapus" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.ID == "" ? "Tambah Menu" : "Edit Menu"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus menu {this.state.Detail.Nama} !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger" id='btnSimpan'><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
